<script lang="ts" setup>
const menuOpenState = ref(false)

const menuItems = ref([
  // {
  //   name: 'BEAM Wear',
  //   relativeUrl: '/selectie/beam-wear',
  // },
  {
    name: 'Eva',
    relativeUrl: 'https://meer.eo.nl/eva',
  },
  {
    name: 'Visie',
    relativeUrl: 'https://meer.eo.nl/visie',
  },
])
</script>
<template>
  <header
    :class="[
      '@container sticky top-0 z-40 flex h-20 w-full items-center justify-start gap-3 bg-white p-5 transition-all sm:px-8 md:gap-4',
    ]"
  >
    <div class="md:hidden">
      <EoIcon
        color="black"
        name="menu"
        size="2xl"
        v-if="!menuOpenState"
        @click="menuOpenState = true"
      />
      <EoIcon
        v-else
        color="black"
        name="x"
        size="2xl"
        @click="menuOpenState = false"
      />
    </div>

    <NuxtLink
      accesskey="1"
      class="@xs:mr-3 flex w-full flex-row justify-center no-underline md:w-1/12 md:justify-start lg:w-1/12"
      to="https://meer.eo.nl/"
    >
      <h3 class="mx-3 my-auto text-2xl text-purple-700">meer</h3>
      <EoLogo
        class=""
        brand="eo"
        size="md"
        color="purple-700"
      />
    </NuxtLink>

    <div
      class="mx-auto hidden items-center justify-center gap-4 px-2 sm:gap-6 md:flex md:gap-8 lg:gap-10 lg:text-lg xl:gap-16"
    >
      <div
        v-for="menuItem in menuItems"
        :key="menuItem.relativeUrl"
      >
        <NuxtLink
          :to="menuItem.relativeUrl"
          :class="[
            `@xs:border-none flex items-center gap-2 border-b-2 no-underline`,
            $route.path === menuItem.relativeUrl
              ? 'border-black text-black'
              : 'border-transparent',
          ]"
        >
          <span
            :class="[
              `@sm:not-sr-only sr-only hidden border-b-2 font-bold leading-tight md:block`,
              $route.path === menuItem.relativeUrl
                ? 'border-black'
                : 'border-transparent',
            ]"
          >
            {{ menuItem.name }}
          </span>
        </NuxtLink>
      </div>
    </div>

    <NuxtLink
      to="https://npostart.nl"
      target="_blank"
      class="text-right md:w-1/12"
    >
      <EoIcon
        color="npo-300"
        name="npo"
        size="2xl"
      />
    </NuxtLink>
  </header>

  <EoModal
    v-model:open="menuOpenState"
    backdrop-color="transparent"
    class="h-full max-w-xl pl-5 pt-6"
    color="gray-800"
    label="Mobiel Menu"
    position="top"
  >
    <EoIcon
      color="white"
      name="x"
      size="2xl"
      @click="menuOpenState = false"
    />
    <div class="h-full min-h-fit w-full text-white">
      <hr class="my-5" />
      <NuxtLink
        v-for="menuItem in menuItems"
        :key="menuItem.relativeUrl"
        :to="menuItem.relativeUrl"
        :class="[
          `@xs:border-none flex flex-col  pl-1 pt-5 text-white no-underline`,
          $route.path === menuItem.relativeUrl
            ? 'border-white text-white'
            : 'border-transparent',
        ]"
        @click="menuOpenState = false"
      >
        <span
          :class="[
            `border-b-2 font-bold leading-tight text-white`,
            $route.path === menuItem.relativeUrl
              ? 'border-gray-800'
              : 'border-transparent',
          ]"
        >
          {{ menuItem.name }}
        </span>
      </NuxtLink>
    </div>
  </EoModal>
</template>
